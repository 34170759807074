import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export const SidePanelComponent = (props) => {
    const [open, setOpen] = useState(false);
    
    useEffect(()  => {
        setOpen(props.open)
    },[props])

    return (
        <SlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={open}
            title={props.title}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                setOpen(false);
            }}
        >
            {props.body}
      </SlidingPane>
    )
}