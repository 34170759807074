import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useConstructor } from '../utils';

let ddmap = ( k, v ) => ( { key: k, value: v } );

let json2SortedReactSelectArray = ( data ) => {
	const items = [];
   	Object.keys( data ).sort( function( a, b ) {
		return data[a].localeCompare( data[b] ) 
		}).map( k => {
			items.push(ddmap( k, data[k] ))
	});
	return items
}

export function SimpleAutoCompleteInput({ textFieldProps, ...props }) {
	const [options, setOptions] = React.useState(props.options)
	const [value, setValue] = React.useState((props.defaultValue)?props.defaultValue:"")

	const updateOptions = (newOptions) => {
		setOptions(newOptions)
	}

	const clear = () => {
        if (props.handleChange)
			props.handleChange('');
		setValue('');
	}

	useConstructor(() => {
		if (props.reset)
			props.reset.subscribe(clear);

		if (props.observable)
			props.observable.subscribe(updateOptions);
	});

	const handleChange = (event, value) => {
		if (props.handleChange)
			props.handleChange(value);
		setValue(value);
	};

	return (
		<div className={props.layout}>
          <Autocomplete
		  	value={value}
            options={json2SortedReactSelectArray(options)}
            getOptionLabel={(option) => (option.value)?option.value:""}
            id={props.id}
            autoComplete
            onChange={handleChange}
            renderInput={(params) => (
            	<TextField {...params} label={props.label} variant="standard" disabled={props.disabled}/>
            )}
			disabled={props.disabled}
            />
		</div>

        
	);
}