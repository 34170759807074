import * as React from 'react';
import { useField } from "formik";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';

let ddmap = ( k, v ) => ( { key: k, value: v } );

let json2SortedReactSelectArray = ( data ) => {
	const items = [];
   	Object.keys( data ).sort( function( a, b ) {
		return data[a].localeCompare( data[b] ) 
		}).map( k => {
			items.push(ddmap( k, data[k] ))
	});
	return items
}

export function AutoCompleteInput({ textFieldProps, ...props }) {
	const [field, meta, helpers] = useField(props.name);
	const [options, setOptions] = React.useState(props.options)
	const { value } = meta;
	const { setValue } = helpers;
	
	const updateOptions = (newOptions) => {
		setOptions(newOptions)
	}

	if (props.observable)
		props.observable.subscribe(updateOptions);

	const handleChange = (event, value) => {
		if (props.handleChange)
			props.handleChange(value);
		setValue(value);
	};

	return (
		<div>
          <Autocomplete
		  	{...field}
            options={json2SortedReactSelectArray(options)}
            getOptionLabel={(option) => (option.value)?option.value:""}
            id={props.id}
            autoComplete
            onChange={handleChange}
            renderInput={(params) => (
            	<TextField {...params} label={props.label} variant="standard" disabled={props.disabled}/>
            )}
			disabled={props.disabled}
            />
		</div>

        
	);
}