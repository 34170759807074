import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from "react";

export const DraggableFilterDialog = ({ open, onClose, paperComponent ,title, description, children, buttons }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperComponent={paperComponent}
    aria-labelledby="draggable-dialog-title"
  >
    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {{description}? <p>{description}</p>: null}
        <div>
          {children}
        </div>
        </DialogContentText>
    </DialogContent>
    <DialogActions>{buttons}</DialogActions>
  </Dialog>
);