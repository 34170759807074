import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useField } from "formik";

import { i18ndb, rest } from '../services';
import { useState,useEffect } from 'react';

const buildOptions = (list) => {
	if (!list)
		return [];
	const items = [];
	for (const [key, value] of Object.entries(list)) {
		items.push(<MenuItem key={key} value={key}>{i18ndb._(value)}</MenuItem>);
	}
	return items;
}

/**
	Props:
	entity: entity containing the metadata in the backend
	metadata: content of the metadata if not taken from the backend
 */
export function Select(props) {
	const [option,setOption] = useState({'':'loading...'})
	const [field, meta, helpers] = useField(props.name);
	const { value } = meta;
	const { setValue } = helpers;

	useEffect(()=>{
		if (props.entity) {
			rest.list(props.entity, {})
				.then(response => {
					setOption(response)
				});
			} else {
				setOption(props.list)

			}
	},[props.entity])
	
	let options = buildOptions(option);

	const handleChange = event => {
		setValue(event.target.value);
	};
  
	return (
		<div>
		  <TextField
			select
			label={props.label}
			value={value}
			onChange={handleChange}
			onBlur={props.onBlur}
			name={props.name}
			disabled={props.disabled}
			>
			{options}
		  </TextField>
		</div>
	);
  }
