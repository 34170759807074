import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import { t } from "../services";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '200px',
    width: '340px'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  

export function ImageDropZone(props) {
    const onDrop = (acceptedFiles) => props.onDropCallback(acceptedFiles[0]);
    const { 
      getRootProps,
      getInputProps, 
      isDragActive, 
      isDragAccept,
      isDragReject 
    } = useDropzone({ onDrop, multiple:false, accept: 'image/*' })
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject,
        isDragAccept
      ]);

    return (
        <div {...getRootProps({style})}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>{t`Drop the files here ...`}</p> :
                    <p>{t`Drag and drop some files here, or click to select files`}</p>
            }
        </div>
    )
}