import React, { useState } from 'react';
import {GUI_LANGUAGE_CHANGE_EVENT} from '../events/Gui';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { rest, t, i18ndb } from '../services';
import { loadingIcon } from '../loadingIcon';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LanguageSelector (props) {

    const [language, setLanguage] = useState(undefined);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    if (!language) {
        setLanguage('en');
      return loadingIcon;
    }
    const handleChange = (lang) => {
      let filter = {};
      filter['key'] = 'language';
      filter['value'] = lang;
      rest.setPreferences(filter)
      .then(
        () => {
          setLanguage(lang);
          setAnchorEl(null);
          i18ndb.setLocale(lang);
          i18ndb.loadCatalog().then(() => GUI_LANGUAGE_CHANGE_EVENT.publish(lang));
        })
    };

	const buildMenu = (languages) => {
		let items = [];
		languages.forEach(l => {
			items.push(<MenuItem onClick={() => handleChange(l.code)} key={l.code}>{l.label}</MenuItem>);
		});
		return items;
	}
	
   return(
        <>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          >
          <FontAwesomeIcon color={'#fff'} icon={faGlobeAfrica} style={{ fontSize: '1.5rem' }} title={t`Select language`} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          >
		   {buildMenu(props.languages)}
        </Menu>
        </>
    )
}